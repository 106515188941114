<template>
  <div>
    <div v-if="!isLoading">
      <!-- Statistik Kelas  -->
      <b-row cols="4">
        <!-- Jumlah Sekolah -->
        <b-col>
          <router-link to="/super/schools">
            <b-card>
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" variant="light-primary">
                    <i class="font-medium-5 ti ti-school" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    Sekolah
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ this.dashboardData.totalSchool }} Sekolah Terdaftar
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-card>
          </router-link>
        </b-col>

        <!-- Jumlah Kelas -->
        <b-col>
          <router-link to="/super/schools">
            <b-card>
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" variant="light-info">
                    <i class="font-medium-5 ti ti-backpack" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    Kelas
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ this.dashboardData.totalClassroom }} Kelas Tersedia
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-card>
          </router-link>
        </b-col>

        <!-- Jumlah Guru -->
        <b-col>
          <router-link to="/super/teachers">
            <b-card>
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" variant="light-danger">
                    <i class="font-medium-5 ti ti-user" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    Guru
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ this.dashboardData.totalTeacher }} Guru Produktif
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-card>
          </router-link>
        </b-col>

        <!-- Jumlah Siswa -->
        <b-col>
          <router-link to="/super/students">
            <b-card>
              <b-media no-body>
                <b-media-aside class="mr-2">
                  <b-avatar size="48" variant="light-warning">
                    <i class="font-medium-5 ti ti-users" />
                  </b-avatar>
                </b-media-aside>
                <b-media-body class="my-auto">
                  <h4 class="font-weight-bolder mb-0">
                    Siswa
                  </h4>
                  <b-card-text class="font-small-3 mb-0">
                    {{ this.dashboardData.totalStudent }} Siswa Kelas Aktif
                  </b-card-text>
                </b-media-body>
              </b-media>
            </b-card>
          </router-link>
        </b-col>

        <!-- Jumlah Materi -->
        <!-- <b-col>
          <b-card>
            <b-media no-body>
              <b-media-aside class="mr-2">
                <b-avatar size="48" variant="light-success">
                  <i class="font-medium-5 ti ti-book-2" />
                </b-avatar>
              </b-media-aside>
              <b-media-body class="my-auto">
                <h4 class="font-weight-bolder mb-0">
                  Materi
                </h4>
                <b-card-text class="font-small-3 mb-0">
                  {{ this.dashboardData.totalMaterial }} Materi Potensial
                </b-card-text>
              </b-media-body>
            </b-media>
          </b-card>
        </b-col> -->
      </b-row>

      <!-- News Berita -->
      <b-row class="mb-2">
        <!-- <b-col lg="12">
        <h2 class="content-header-title">
          Berita
        </h2>
      </b-col> -->
        <b-col lg="12">
          <b-carousel
            v-if="newsImages.length > 0"
            id="carousel-caption"
            controls
            indicators
          >
            <b-carousel-slide
              v-for="item in newsImages"
              :key="item.id"
              :caption-html="
                `
                <span class='text-white'>
                  ${item.title}
                </span> </br>
                <a href='/super/news/${item.id}' class='mt-1 btn btn-primary' target='_self'>Detail Berita</a>`
              "
              class="card-img-overlay news-slide-content"
              :img-src="item.image"
            />
          </b-carousel>

          <div v-if="newsImages.length === 0" class="text-center mt-4">
            <b-spinner variant="primary" class="mr-1" />
            <p class="mt-1">
              Memuat Berita
            </p>
          </div>
        </b-col>
      </b-row>

      <!-- Chart -->
      <b-row>
        <b-col lg="12">
          <dashboard-most-active-school-chart
            :title="'Sekolah Berdasarkan Poin Keaktifan'"
            :data-chart="chartBySchoolActivities"
          />
        </b-col>
      </b-row>

      <!-- Chart -->
      <b-row>
        <b-col lg="6">
          <dashboard-most-active-school-chart
            :title="'Sekolah Berdasarkan Jumlah Siswa'"
            :data-chart="chartByStudent"
          />
        </b-col>

        <b-col lg="6">
          <dashboard-most-active-school-chart
            :title="'Sekolah Berdasarkan Jumlah Guru'"
            :data-chart="chartByTeacher"
          />
        </b-col>
      </b-row>
    </div>

    <div v-if="isLoading" class="d-flex justify-content-center vertical-center">
      <div class="text-center mt-4">
        <b-spinner variant="primary" class="mr-1" />
        <p class="mt-1">
          Memuat Dashboard
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BCarousel,
  BCarouselSlide,
  BSpinner,
  BCard,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BCardText,
} from 'bootstrap-vue'
import { $themeColors } from '@themeConfig'

import client from '@/libs/http/axios-config'
import { checkAuthorizeRole } from '@/auth/utils'

import DashboardMostActiveSchoolChart from './DashboardMostActiveSchoolChart.vue'

const chartColors = {
  primaryColorShade: '#836AF9',
  yellowColor: '#ffe800',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
  blueColor: '#2c9aff',
  blueLightColor: '#84D0FF',
  greyLightColor: '#EDF1F4',
  tooltipShadow: 'rgba(0, 0, 0, 0.25)',
  lineChartPrimary: '#666ee8',
  lineChartDanger: '#ff4961',
  labelColor: '#6e6b7b',
  grid_line_color: 'rgba(200, 200, 200, 0.2)',
}

export default {
  components: {
    BRow,
    BCol,
    BCarousel,
    BCarouselSlide,
    BSpinner,
    BCard,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BCardText,

    DashboardMostActiveSchoolChart,
  },
  data() {
    return {
      isLoading: true,
      dashboardData: {},
      newsImages: [],

      // Chart Config
      chartByStudent: {
        options: {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: 'top',
            },
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                stacked: true,
                display: true,
                gridLines: {
                  zeroLineColor: chartColors.grid_line_color,
                  borderColor: 'transparent',
                  color: chartColors.grid_line_color,
                  drawTicks: false,
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  min: 0,
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                stacked: true,
                display: true,
                gridLines: {
                  display: false,
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
          },
        },
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: $themeColors.info,
              borderColor: 'transparent',
              barThickness: 15,
            },
          ],
        },
      },
      chartByTeacher: {
        options: {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: 'top',
            },
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                stacked: true,
                display: true,
                gridLines: {
                  zeroLineColor: chartColors.grid_line_color,
                  borderColor: 'transparent',
                  color: chartColors.grid_line_color,
                  drawTicks: false,
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  min: 0,
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                stacked: true,
                display: true,
                gridLines: {
                  display: false,
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
          },
        },
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: $themeColors.success,
              borderColor: 'transparent',
              barThickness: 15,
            },
          ],
        },
      },

      chartBySchoolActivities: {
        options: {
          elements: {
            rectangle: {
              borderWidth: 2,
              borderSkipped: 'top',
            },
          },
          tooltips: {
            // Updated default tooltip UI
            shadowOffsetX: 1,
            shadowOffsetY: 1,
            shadowBlur: 8,
            shadowColor: chartColors.tooltipShadow,
            backgroundColor: $themeColors.light,
            titleFontColor: $themeColors.dark,
            bodyFontColor: $themeColors.dark,
          },
          responsive: true,
          maintainAspectRatio: false,
          responsiveAnimationDuration: 500,
          legend: {
            display: false,
          },
          scales: {
            xAxes: [
              {
                stacked: true,
                display: true,
                gridLines: {
                  zeroLineColor: chartColors.grid_line_color,
                  borderColor: 'transparent',
                  color: chartColors.grid_line_color,
                  drawTicks: false,
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  min: 0,
                  fontColor: chartColors.labelColor,
                },
              },
            ],
            yAxes: [
              {
                stacked: true,
                display: true,
                gridLines: {
                  display: false,
                },
                scaleLabel: {
                  display: true,
                },
                ticks: {
                  fontColor: chartColors.labelColor,
                },
              },
            ],
          },
        },
        data: {
          labels: [],
          datasets: [
            {
              data: [],
              backgroundColor: $themeColors.warning,
              borderColor: 'transparent',
              barThickness: 15,
            },
          ],
        },
      },
    }
  },
  async mounted() {
    await client
      .get('/admin/dashboard')
      .then((response) => {
        this.dashboardData = response.data.data

        // Sort By Student Count
        const studentCount = response.data.data.schools.sort(
          (a, b) => b.student_count - a.student_count
        )

        studentCount.map((school) => {
          this.chartByStudent.data.labels.push(school.name)
          this.chartByStudent.data.datasets[0].data.push(school.student_count)
        })

        // Sort By Teacher Count
        const teacherCount = response.data.data.schools.sort(
          (a, b) => b.teacher_count - a.teacher_count
        )

        teacherCount.map((school) => {
          this.chartByTeacher.data.labels.push(school.name)
          this.chartByTeacher.data.datasets[0].data.push(school.teacher_count)
        })

        const schoolActivities = response.data.data.schools.sort(
          (a, b) => b.classified_grade - a.classified_grade
        )

        schoolActivities.map((school) => {
          this.chartBySchoolActivities.data.labels.push(school.name)
          this.chartBySchoolActivities.data.datasets[0].data.push(
            school.classified_grade
          )
        })
      })
      .catch((error) => {
        console.error(error)
      })

    await client
      .get('/news?status=published')
      .then((response) => {
        this.newsImages = response.data.data

        this.isLoading = false
      })
      .catch((error) => {
        console.error(error)
      })
  },
  methods: {
    checkAuthorizeRole,
  },
}
</script>

<style lang="scss">
.card-img-overlay {
  padding: 0 !important;
}

.news-slide-content {
  border-radius: 0.428rem !important;

  img {
    height: 350px;
    border-radius: 0.428rem !important;
    object-fit: cover;
  }
}
</style>
